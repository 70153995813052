.problem_home .am-navbar.am-navbar-mode,
.question_home .am-navbar.am-navbar-mode {
    position: fixed;
    width: 100%;
    z-index: 1;
}

.problem_info,
.question_info {
    padding: 5px 15px;
    border-bottom: 1px solid #ddd;
    position: relative;
    background-color: #fff;
}

.problem_header {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.problem_header_img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 40px;
}

.problem_header_img img,
.problem_question_img img {
    width: 100%;
    height: 100%;
}

.problem_header_name>div:first-child {
    font-size: 16px;
    font-weight: bold;
}

.problem_header_name>div:last-child {
    font-size: 12px;
    color: #b6b6b6;
}

.problem_title>span>span {
    background: #f59701;
    padding: 2px 8px;
    color: #fff;
    margin-right: 8px;
    font-size: 12px;
    font-weight: normal;
    border-radius: 10%;
}

.problem_title>span {
    font-size: 16px;
    font-weight: bold;
}

.problem_question {
    display: flex;
    padding: 5px 0;
}

.problem_question_content {
    flex: 3;
}

.problem_question_img {
    flex: 1;
    margin-left: 5px;
}

.problem_modal_img .weui-uploader__title {
    text-align: left;
    color: #000;
}

.ploblem_info_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.problem_del {
    height: 22px;
    display: flex;
    align-items: center;
}

.problem_del_text {
    font-size: 14px;
    color: #a0a0a0;
}

.problem_del_img {
    width: 14px;
    height: 22px;
}

.problem_del_img img {
    width: 14px;
    height: 14px;
}

.question_main {
    padding-top: 40px;
}

.question_problem {
    padding: 20px 15px 5px;
    font-size: 16px;
    font-weight: bold;
}

.qusetion_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 10px;
}

.qusetion_btn>div:first-child {
    color: #b6b6b6;
    font-size: 13px;
    padding-left: 3px;
}

.question_separator {
    height: 5px;
    background: #f6f6f6;
}

.question_info .am-image-picker-list {
    margin: 0 !important;
    padding: 8px 0 0;
}

.question_del {
    position: absolute;
    top: 15px;
    right: 15px;
}

.question-add .am-image-picker-list .am-image-picker-item .am-image-picker-item-remove {
    height: 0;
}

.question-add .weui-uploader__input-box {
    display: none;
}

.question-add .weui-uploader__hd {
    display: none;
}

.question-add .weui-cell:before {
    border: none;
}

.question-add .weui-cell {
    padding: 10px 0;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    background: #fff;
    display: flex;
    align-items: center;
    border-top: 1px solid #ddd;
}

.footer_info {
    display: flex;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.info_img {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info_img img {
    width: 24px;
    height: 24px;
}

.info_img span {
    font-size: 11px;
    margin-top: 3px;
}

.footer_add {
    position: absolute;
    bottom: 9px;
    left: 50%;
    width: 38px;
    height: 38px;
    transform: translatex(-50%);
}

.footer_add img {
    height: 100%;
    width: 100%;
}

.search_modal.am-modal-transparent {
    width: 100%;
    height: 100%;
}

.search_modal.am-modal-transparent .am-modal-content {
    border-radius: 0;
    padding: 0;
}

.search_modal.am-modal-transparent .am-modal-content .am-modal-body {
    padding: 0;
}

.search_modal .modal_img {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: .375rem;
}

.search_modal .modal_img img {
    width: 100%;
    height: 100%;
}

.taskAdd_enclosure {
    padding: 0 15px;
}

.taskAdd_enclosure_file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
}

.headline-ul {
    display: flex;
    overflow-x: scroll;
    word-break: keep-all;
    height: 48px;
    line-height: 48px;
    box-shadow: 2px 0 2px #ccc;
    position: fixed;
    z-index: 1;
    background-color: #fff;
    width: 100%;
}

.headline-ul li {
    text-align: center;
    color: #818181;
    font-size: 15px;
    padding: 0 10px;
    margin: 0 20px;
    transition: color 1s linear, border-bottom 1s linear;
}

.headline-ul .li-hover {
    border-bottom: 1px solid #333333;
    color: #333333;
    font-weight: bold;
}

.content-main {
    padding: 0 15px 10px;
    background-color: #fff;
    margin: 0 0 5px;
}

.content-header {
    display: flex;
    justify-content: space-between;
    padding: 17px 0 9px;
}
.content-header-main {
    display: flex;
    align-items: center;
}

.header-image {
    height: 35px;
    width: 35px;
}

.header-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.header-message {
    padding-left: 10px;
}

.header-message-nick {
    font-size: 13px;
    color: #333333;
    padding-bottom: 3px;
}

.header-message-time {
    font-size: 11px;
    color: #818181;
}

.content-title {
    font-size: 15px;
    color: #333333;
    line-height: 2;
}

.content {
    font-size: 13px;
    color: #505050;
    line-height: 1.5;
}
.content-image {
    display: grid;
    padding-top: 10px;
    grid-template-columns: repeat(3,1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
}

.body-height {
    background-color: #F5F5F5;
}
.databody {
    box-shadow: 0 0 0 5px #F5F5F5;
}
